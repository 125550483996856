/* eslint no-param-reassign: ["error", { "props": false }] */

import range from 'just-range';
import get from 'just-safe-get';
import includes from 'array-includes';

import { partition } from './Array';

export const Classes = {
  CLASS_A: 'CLASS_A',
  CLASS_B: 'CLASS_B',
  CLASS_C: 'CLASS_C',
  FIFTH_WHEEL: 'FIFTH_WHEEL',
  POPUP_CAMPER: 'POPUP_CAMPER',
  TRAVEL_TRAILER: 'TRAVEL_TRAILER',
  TOY_HAULER: 'TOY_HAULER',
  TRUCK_CAMPER: 'TRUCK_CAMPER',
  WHEELCHAIR_ACCESSIBLE: 'WHEELCHAIR_ACCESSIBLE',
  PARK_MODEL: 'PARK_MODEL',
};

// TODO: The __SEARCH variables are class names we use for search, but not elsewhere
export const ClassNames = {
  CLASS_A: 'Class A Motor Home',
  CLASS_B: 'Class B Camping Van',
  CLASS_C: 'Class C Motor Home',
  FIFTH_WHEEL: 'Fifth Wheel',
  POPUP_CAMPER: 'Pop-up Camper',
  POPUP_CAMPER_SEARCH: 'Pop-up Camper',
  TRAVEL_TRAILER: 'Travel Trailer',
  TOY_HAULER: 'Toy Hauler',
  TOY_HAULER_SEARCH: 'Toy Hauler',
  TRUCK_CAMPER: 'Truck Campers',
  PARK_MODEL: 'Park Model',
};

export const ClassNamesShort = {
  CLASS_A: 'Class A',
  CLASS_B: 'Class B',
  CLASS_C: 'Class C',
  FIFTH_WHEEL: '5th Wheel',
  TRAVEL_TRAILER: 'Travel Trailer',
  TOY_HAULER: 'Toy Hauler',
  POPUP_CAMPER: 'Popup',
};

export const isDrivable = ({ type = null, name = null }) =>
  type === Classes.CLASS_A ||
  type === Classes.CLASS_B ||
  type === Classes.CLASS_C ||
  name === ClassNames.CLASS_A ||
  name === ClassNames.CLASS_B ||
  name === ClassNames.CLASS_C;

export const isTowable = ({ type = null, name = null }) =>
  type === Classes.FIFTH_WHEEL ||
  type === Classes.TRAVEL_TRAILER ||
  type === Classes.TOY_HAULER ||
  type === Classes.POPUP_CAMPER ||
  name === ClassNames.FIFTH_WHEEL ||
  name === ClassNames.TRAVEL_TRAILER ||
  name === ClassNames.TOY_HAULER ||
  name === ClassNames.TOY_HAULER_SEARCH ||
  name === ClassNames.POPUP_CAMPER ||
  name === ClassNames.POPUP_CAMPER_SEARCH;

export const ClassOptions = [
  {
    id: Classes.CLASS_A,
    label: 'Class A',
    value: ClassNames.CLASS_A,
  },
  {
    id: Classes.CLASS_B,
    label: 'Class B',
    value: ClassNames.CLASS_B,
  },
  {
    id: Classes.CLASS_C,
    label: 'Class C',
    value: ClassNames.CLASS_C,
  },
  {
    id: Classes.TRAVEL_TRAILER,
    label: 'Travel Trailer',
    value: ClassNames.TRAVEL_TRAILER,
  },
  {
    id: Classes.FIFTH_WHEEL,
    label: 'Fifth Wheel',
    value: ClassNames.FIFTH_WHEEL,
  },
  {
    id: Classes.TOY_HAULER,
    label: 'Toy Hauler',
    value: ClassNames.TOY_HAULER,
    searchValue: ClassNames.TOY_HAULER_SEARCH,
  },
  {
    id: Classes.POPUP_CAMPER,
    label: 'Pop-up Camper',
    value: ClassNames.POPUP_CAMPER,
    searchValue: ClassNames.POPUP_CAMPER_SEARCH,
  },
  {
    id: Classes.PARK_MODEL,
    label: 'Park Model',
    value: ClassNames.PARK_MODEL,
    searchValue: ClassNames.PARK_MODEL,
  },
];

export const DrivableClassFilterOptions = [
  {
    id: Classes.CLASS_A,
    label: ClassNamesShort.CLASS_A,
    value: ClassNames.CLASS_A,
    description: 'Driver should be comfortable driving something as large as a bus',
  },
  {
    id: Classes.CLASS_B,
    label: ClassNamesShort.CLASS_B,
    value: ClassNames.CLASS_B,
    description: 'Comparable to driving and maneuvering an oversized van',
  },
  {
    id: Classes.CLASS_C,
    label: ClassNamesShort.CLASS_C,
    value: ClassNames.CLASS_C,
    description: 'Comparable to driving a truck and popular option for beginner RV drivers',
  },
];

export const TowableClassFilterOptions = [
  {
    id: Classes.FIFTH_WHEEL,
    label: ClassNamesShort.FIFTH_WHEEL,
    value: ClassNames.FIFTH_WHEEL,
    description: 'Attaches to a tow vehicle via a gooseneck extension in the truck bed',
  },
  {
    id: Classes.TRAVEL_TRAILER,
    label: ClassNamesShort.TRAVEL_TRAILER,
    value: ClassNames.TRAVEL_TRAILER,
    description: 'Trailer options exist for every kind of SUV or pickup tow vehicle',
  },
  {
    id: Classes.TOY_HAULER,
    label: ClassNamesShort.TOY_HAULER,
    value: ClassNames.TOY_HAULER,
    description: 'Can be towed by many SUV or pickup tow vehicles',
  },
  {
    id: Classes.POPUP_CAMPER,
    label: ClassNamesShort.POPUP_CAMPER,
    value: ClassNames.POPUP_CAMPER,
    searchValue: ClassNames.POPUP_CAMPER_SEARCH,
    description: 'Can be towed by ordinary passenger vehicles',
  },
];

export const ElMonteFilterOptions = [
  {
    id: Classes.CLASS_A,
    label: 'Class A',
    value: ClassNames.CLASS_A,
  },
  {
    id: Classes.CLASS_C,
    label: 'Class C',
    value: ClassNames.CLASS_C,
  },
];

export const ClassFilterOptions = [...DrivableClassFilterOptions, ...TowableClassFilterOptions];

export function partitionClasses(classes) {
  return partition(Object.entries(classes), cls => isDrivable({ name: cls[0] }));
}

export function toggleClassValue(classes, toggleValue) {
  return classes.reduce((accum, cls) => {
    accum[cls[0]] = toggleValue;
    return accum;
  }, {});
}

export function classOptionsMapToArray(obj) {
  return ClassOptions.filter(({ searchValue, value }) => obj[searchValue] || obj[value]).map(
    ({ searchValue, value }) => searchValue || value
  );
}

export function classOptionsArrayToMap(arr) {
  return ClassOptions.reduce(
    (acc, { searchValue, value }) => ({
      ...acc,
      [searchValue || value]: searchValue ? includes(arr, searchValue) : includes(arr, value),
    }),
    {}
  );
}

export const classOptionsArrayToBoolObject = arr => {
  return arr.reduce((accum, value) => {
    return {
      ...accum,
      [value.searchValue || value.value]: true,
    };
  }, {});
};

// Use current year Jan-May
// Use following year June-December
export function getNewestModelYear() {
  const today = new Date();
  return today.getFullYear() + 1;
}

export function getOldestModelYear() {
  const today = new Date();
  return today.getFullYear() - 15;
}

export function getModelYearOptions() {
  const oldest = getOldestModelYear();
  const newest = getNewestModelYear();

  return range(oldest, newest + 1).map(year => ({
    value: year.toString(),
    label: year.toString(),
  }));
}

const approvedInsuranceStatuses = ['approved', 'commercial', 'liability'];

export function canInstantBook(isInstantBook, insuranceStatus) {
  return isInstantBook && includes(approvedInsuranceStatuses, insuranceStatus);
}

export const getOwnerName = rv => {
  const ownerName = get(rv, ['owner_details', 'first_name']);

  return ownerName || 'the owner';
};

export const getOwnerPhoto = rv => get(rv, ['owner_details', 'photo', 'filename']);

export const getYearMakeModel = rv => {
  const { rv_year: year, rv_make: make, rv_model: model, rv_make_model: makeModel } = rv;

  if (makeModel) {
    return [year, makeModel].filter(x => !!x).join(' ');
  }

  return [year, make, model].filter(x => !!x).join(' ');
};

export const getVehicleRegion = ({ region, location }) => {
  const city = get(region, 'name');
  const stateCode = get(region, 'state_short');
  const zipCode = get(location, 'postal_code', '');

  if (!city || !stateCode) {
    return null;
  }

  return `${city}, ${stateCode} ${zipCode}`.trim();
};

// Convert a score out of 100 to a 5-point scale
export const convertReviewToFivePointScale = (reviewCount, scoreOutOf100) => {
  if (!reviewCount || scoreOutOf100 < 0 || scoreOutOf100 > 100) {
    return null;
  }

  return parseFloat(((scoreOutOf100 / 100) * 5).toFixed(1));
};
